exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-health-check-js": () => import("./../../../src/pages/health_check.js" /* webpackChunkName: "component---src-pages-health-check-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-work-actionera-js": () => import("./../../../src/pages/work-actionera.js" /* webpackChunkName: "component---src-pages-work-actionera-js" */),
  "component---src-pages-work-qoi-js": () => import("./../../../src/pages/work-qoi.js" /* webpackChunkName: "component---src-pages-work-qoi-js" */),
  "component---src-pages-work-smartpro-js": () => import("./../../../src/pages/work-smartpro.js" /* webpackChunkName: "component---src-pages-work-smartpro-js" */)
}

